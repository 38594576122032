#mappa-manicomi {
  position: relative;
  min-height: 300px;

  & svg {
    overflow: visible;
  }
}

.map__region {
  stroke: #5E5E77;
  stroke-width: 1px;
  fill: none;
  pointer-events: none;
}

.map__point {
  opacity: .5;
  cursor: pointer;
  fill: var(--acquaColor);
}

.map__circles .is-selected {
  fill: var(--blueColor);
  opacity: 1;
}

#tooltip {
  padding: 5px 7px;
  max-width: 250px;
  opacity: 0;
  position: absolute;
  background-color: var(--blueColor);
  z-index: 1;
  color: var(--whiteColor);
  pointer-events: none;

  & span {
    display: inline-block;
    width: 100%;
    text-align: right;
    font-size: .85em;
    font-weight: 700;
  }
}