:root {
  
  /* Colors */
  --blackColor: #22242B;
  --darkGreyColor: #939393;
  --lightGreyColor: #c6c6c6;
  --whiteColor: #fff;
  --blueColor : #191654;
  --acquaColor: #6BD6C2;
 

  /* Layout */
  --smallVerticalMargin: {
    margin-top: 4%;
    margin-bottom: 4%;
  }

  --mediumVerticalMargin: {
    margin-top: 6%;
    margin-bottom: 6%;
  }

  --largeVerticalMargin: {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  --exagerateVerticalMargin: {
    margin-top: 15%;
    margin-bottom: 15%;
  }

  --main-column: {
    width: 1024px;
    margin-left: calc(50% - 512px);
    margin-right: calc(50% - 512px);
    display: inline-block;
  }

  --main-column-small: {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    display: inline-block;
  }
}

/* Media query */
@custom-media --not-large-screen (width < 1100px);
@custom-media --medium-screen (width >= 768px) and (width < 1024px);
@custom-media --small-screen (width < 768px);
@custom-media --mini-screen (width < 375px);

@custom-media --sedici-noni (aspect-ratio > 16/9);
@custom-media --sedici-dodicesimi (aspect-ratio < 16/12);