@import 'normalize.css';
@import 'flickity/css/flickity.css';
@import url('https://fonts.googleapis.com/css?family=Catamaran:300,400,700,900|Lora:400,600,400i,600i');

/* Lin */
@import 'lib/base.css';

/* Components */
@import 'components/home.css';
@import 'components/navigation.css';
@import 'components/landing.css';
@import 'components/carousel.css';
@import 'components/audio-player.css';
@import 'components/timeline.css';
@import 'components/mappa.css';
@import 'components/custom-rules.css';
@import 'components/login.css';
@import 'components/pages.css';

@lost gutter 11.5px;

body {
  font-family: Catamaran, Sans-Serif;
  font-weight: 300;
  line-height: 1.4em;
  color: var(--blackColor);

  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

a,
a:visited,
a:hover,
a:active {
  color: var(--blackColor);
  text-decoration: none;
}

p {
  margin: 0;
}

figure {
  margin: 0;
}

#progress {
  position: fixed;
  background-color: var(--acquaColor);
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  z-index: 1000;
}
