/* Carousel */

.content__slideshow_container {
  @apply --largeVerticalMargin;
  position: relative;
  width: 100%;
  float: left;
}

.content__slideshow {
  
  
  width: calc(100% - 44px);
  margin-right: 22px;
  margin-left: 22px;
  display: inline-block;
}

.carousel-cell {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 66.666%;

  & img {
    position: absolute;
    width: 100%;
  }
}

.slideshow__caption {
  bottom: calc(10% - 36px);
  width: calc(100% - 44px);
  margin: -10px 22px;
  padding: 10px 0;
  text-align: left;
  color: var(--darkGreyColor);
  font-family: 'Lora', serif;
  font-style: italic;
  font-size: .9em;
}

.flickity-prev-next-button {
  &.previous,
  &.next {
    left: -22px;
    background-color: transparent;

    cursor: pointer;

    & svg {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('../../assets/svg/arrow.svg');
      background-position: center; 
      width: 44px;
      height: 44px;

      transition: transform .2s ease;
      transform-origin: 50% 50%;
      transform-origin: center center;
    }
  }

  &.previous:hover {
    &:before {
      transform: scale(1.1);
    }
  }

  &.next:hover {
    &:before {
      transform: rotate(180deg) scale(1.1);
    }
  }

  &.next {
    left: auto;
    right: -22px;

    &:before {
      transform: rotate(180deg);
    }
  }
}

.flickity-page-dots {
  display: none;
}

/* End of Carousel */