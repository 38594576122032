.landing__trailer {
  position:relative;
  padding-bottom: 56.25%;
  height: 0;

  @media screen and (min-aspect-ratio: 16/12) {
    height: 100vh;
    width: auto;
    padding-bottom: 0;
  }
}

.trailer__mask {
  position: absolute;
  background-color: rgba(34,36,43,.4);
  width: 100%;
  height: 100%;
  z-index:1;

}

.mask__title {
  width: 40%;
  margin-left: 30%;
  margin-top:10%;
  color:var(--whiteColor);
  font-family: Lora, serif;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  font-size: 1.8em;
  line-height: 1.2em;

  @media (--medium-screen) {
    font-size: 1.1em;
  }

  @media (--small-screen) {
    display:none;
  }
}

.mask__play {
  position:relative;
  background: none;
  height: 10%;
  border:none;
  outline:none;
  margin-left: 50%;
  cursor:pointer;

  &:before {
    content:'';
    position:absolute;
    left:-40px;
    top: 50%;
    height: 80px;
    width: 80px;
    background-image: url('/assets/svg/trailer-play.svg');
    background-repeat: no-repeat;

    @media (--medium-screen) {
    height:60px;
    width: 60px;
    left:-30px;
    top:70%;
    }

    @media (--small-screen) {
    height:70px;
    width:70px;
    left:-35px;
    top:340%;
  }
  }
}

.mask-play__text {
  position:absolute;
  left: -28.5px; 
  top: 180%;
  color: var(--acquaColor);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em;
  font-family: 'Catamaran', Sans-Serif;
  text-align: center;

  @media (--medium-screen) {
    top:220%;
  }

  @media (--small-screen) {
    display:none;
  }
}

.mask__arrow {
  position: relative;
  background: none;
  height: 10%;
  border:none;
  outline:none;
  margin-left: 50%;
  cursor:pointer;
  margin-top: 20%;


  &:after {
    content:'';
    position: absolute;
    left:-22px;
    width: 44px;
    height: 44px;
    background-image: url('/assets/svg/arrow-timeline.svg');
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }

  @media (--small-screen) {
    display:none;
  }

  @media (--medium-screen){
    display:none;
  }
}

.mask-arrow__text {
  position:absolute;
  left: -30.7px; 
  top:15%;
  color: var(--acquaColor);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em;
  font-family: 'Catamaran', Sans-Serif;
  text-align: center;

  @media (--medium-screen) {
    top:5%;
  }
}

.trailer__video {
  position: absolute;
  overflow: hidden;
  z-index:0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-aspect-ratio: 16/12) {
    flex-direction: row;
  }

  @media screen and (max-aspect-ratio: 16/9) and (min-aspect-ratio: 16/12) {
    flex-direction: column;
  }
}

.trailer__video video {
  width: 100%;
  
  @media screen and (min-aspect-ratio: 16/12) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-aspect-ratio: 16/9) and (min-aspect-ratio: 16/12) {
    width: auto;
    height: 100%;
  }
}

.landing__intro {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;

  @media (--small-screen) {
    height: auto;
  }
}

.intro__container {
  @apply --main-column;
  
  @media (--not-large-screen) {
    @apply --main-column-small;
  }

  @media (--small-screen) {
    margin-top: 5%;
  }

  & .content__corpo_dx-quattro span {
    color: var(--acquaColor);
    font-weight: 400;
  }
}

.intro__text {
  font-family: Lora, serif;
  font-weight: 600;
  font-style: italic;

  @media (--small-screen){
    margin-bottom: 5%;
  }

  @media (--medium-screen){
    lost-offset:3/8;
    margin-top: 10%;
  }
}

.intro__title {
  font-family: Catamaran, Sans-Serif;
  font-weight: 700;
  color: var(--blueColor);
  font-size: 7em;
  line-height: 0.9em;
  transform: translateY(-10%);

  & span {
    text-decoration: underline;
  }

  @media (--small-screen){
    display:none;
  }

  @media (--medium-screen){
    display:none;
  }
}

.intro__title-mobile {
  display: none;

  @media (--small-screen){
    display: block;
    margin-bottom: 10px;
    width: 80%;
  }

  @media (--medium-screen){
    display:block;
  }
}