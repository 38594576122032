/* ---- about ---- */

#about {
	background-color: var(--blueColor);
	height: 100vh;

	@media (--small-screen) {
		height: auto;
	}

}

.about__navigation a,
.glossario__navigation a{
	color: var(--whiteColor) !important; 
	width: 100px;
}

.about__content {
	padding: 10%;

	& .about_info {
			lost-column: 3/8;

			@media (--small-screen){
				lost-column: 7/8;
			}
	}

	@media (--medium-screen){
		padding: 20% 10%;
	}
}

.about__title {
	font-family: Catamaran, serif;
	color: var(--whiteColor);
	font-weight: 700;
	font-size: 2.8em;
	line-height: 1em;

	@media (--small-screen){
		font-size:2em;
		margin-top: 20%;
	}

	@media (--medium-screen){
		font-size:2.2em;
		line-height: 1em;
	}

	@media (--mini-screen){
		font-size:1.7em;
	}
}
	
.about__subtitle, .bibliografia__subtitle {
	font-family: Lora, serif;
	color: var(--whiteColor);
	font-weight: 400;
	font-style: italic;
	padding-top: 20px;
}

.about__text {
	lost-offset: 1/8;
	color: var(--whiteColor);
	margin-top: 20%;
	letter-spacing: 0.02em;

	@media (--medium-screen){
		lost-offset:0/8;
	}

	@media (--small-screen){
		lost-offset:0/8;
	}
}
	
.about__author{
	color: var(--whiteColor); 
	lost-column: 4/8 2;
	text-align: justify;
	hyphens: auto;

	@media (--small-screen){
		lost-column: 7/8;
		lost-offset: 0/8;
		text-align: left;
		margin-top: 10%;
	}

}

.author__name{
	color: var(--acquaColor);
	font-weight: 600;
	font-variant: small-caps;
	font-size: 1.3em;
}

#author__text {
	margin-top: 10%;
	}

.about__text_italic {
	font-family: Lora, serif;
	font-style: italic;
}

.about__text_link {
	font-variant: small-caps;
	font-size: 1.1em;
	text-decoration: underline;
	font-weight: 400;
	color: var(--acquaColor) !important;
}

#about-firma-tva{
	@media (--small-screen){
	display: none;
	}
}

#about-firma-tva-mobile{
	display: none;

	@media (--small-screen){
	display: inline-block;
	margin-top: 10%;
	color:var(--whiteColor);
	}

	& a {
		@media (--small-screen){
			font-size: 1.3em;
		}
	}
}

/* ---- glossario ---- */

.glossario__navigation a{
	color: var(--blackColor) !important;
}

.glossario__content, .bibliografia__content, .sitografia__content {
	padding: 10%;
	height: auto;

	@media (--small-screen){
		padding: 10% 0 10% 5%;
	}
}

.glossario__title, .bibliografia__title, .sitografia__title {
	font-family: Catamaran, serif;
	color: var(--blueColor);
	font-weight: 700;
	font-size: 2.8em;
	margin-bottom: 40px;

	@media (--small-screen) {
		margin-top:15%;
		font-size:2.5em;
	}
}

.glossario__termini {
	margin: 30px 0;
}

.glossario_elenco,.bibliografia__elenco, .sitografia__elenco {
	lost-column: 6/8;
	lost-offset:1/8;
}

.glossario__parola {
	text-transform: uppercase;
	font-size: 0.9em;
	font-weight: 600;
	margin-bottom: 6px;
	color: var(--blackColor)
}


/* ---- bibliografia ---- */

.bibliografia__title {
		margin-bottom: 10px;
}

.bibliografia__subtitle {
	color: var(--blueColor);
	margin-bottom: 40px;
}


.bibliografia__risorsa, .sitografia__risorsa {
	margin-top: 15px;
}

.risorsa__autore {
	font-weight: 600;

}

.risorsa__titolo{
	font-family: Lora, serif;
	font-style: italic;
	font-weight: 400;
}

.risorsa__data {
	font-weight: 400;
}

.sitografia__content {
	lost-column: 6/8;
}

.sitografia__risorsa {
	font-weight: 400;
	text-decoration: underline;
	
	& a:hover{
		color: var(--acquaColor);
	}
}



