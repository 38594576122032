input {
  border: 1px solid black;
}

#login {
  width: 200px;
  margin: 30vh auto;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & input {
    width: calc(100% - 4px);
    margin-bottom: 10px;
  }

  & button {
    width: calc(100% - 2px);
    padding: 5px 0;
    margin: 20px 0;
    background-color: var(--blueColor);
    color: var(--whiteColor);
    border: solid 1px var(--blueColor);
    cursor: pointer;
    transition: all .2s ease;
  }

  & button:hover {
    color: var(--blueColor);
    background-color: var(--whiteColor);
  }
}