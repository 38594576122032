.audio__container {
  position: absolute;
  bottom: 20%;
  left:-22px;
  width: 85%;
  height: 44px;
}

.audio__play,
.audio__back {
  display: block;
  width: 44px;
  height: 44px;
  transition: transform .2s ease;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;

  &:after{
    content:'';
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('../../assets/svg/play.svg');
    background-repeat: no-repeat;
  }
  &:hover{
    transform: scale(1.1);
  };
}

.audio__back {
  left: 100%;
  width: 42px;
  height: 42px;

  &:after{
    background-image: url('../../assets/svg/back-4.svg');
  };
}

.audio__container .is-playing {
  &:after{
    background-image: url('../../assets/svg/pause.svg');
  };
}

.audio__progress {
  width: 80%;
  height: 2px;
  position: absolute;
  bottom: calc(50% - 1px);
  left: 13%;
  background-color: var(--whiteColor);

  @media (--small-screen){
    width: 75%;
    left:22%;
  }

  @media (--medium-screen){
    width: 78%;
    left:18%;
  }
}

.progress__bar {
  position: absolute;
  height: 6px;
  top:-2px;
  background-color: var(--acquaColor);
}

.audio__length {
  position: absolute;
  left: calc(93% - 42.73px);
  bottom: 60%;
  color: var(--whiteColor);
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.05em;
}