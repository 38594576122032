.home__timeline {
  & .carousel-cell {
    padding: 0;
    height: auto;
  }
}

.home__timeline .flickity-prev-next-button {
  &.previous {
    left:0;
    lost-offset: 1/10;
  }

  &.previous:before {
    background-image: url('../../assets/svg/arrow.svg');
  }

  &.next {
    right: calc(10% - 22px);
  }

  &.next:before {
    background-image: url('../../assets/svg/arrow.svg');
  }
}

.timeline__slide {
  width: 90%;
  height: 100vh;
  float: left;

  &:before{
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 15%;
    left: -10%;
    background-color: var(--blueColor);
  }

  @media (--small-screen) {
    width: 100%;
    overflow-y: scroll;

    &:before {
      display: none;
    }
  }
}

.timeline__slide_blue {
  background-color: var(--blueColor);
  color: var(--whiteColor);

  &:before{
    background-color: var(--whiteColor);
  }
}

.timeline__space-right {
  width: 10%;
  height: 100vh;
  float: left;

  @media (--small-screen) {
    display: none;
  }
}

.timeline__space-right_blue {
  background-color: var(--blueColor);
}

.timeline-slide__left,
.timeline-slide__right {
  lost-column: 3/9;
  height: 73%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media (--small-screen) {
    lost-column: 6/9;
    lost-offset: 2/9;
    height: auto;
    min-height: 25%;
  }
}

.timeline-slide__left {
  lost-offset: 2/9;
}

.timeline-slide__main-title {
  position: absolute;
  top: 33%;
  width: 80%;

  font-family: Lora;
  font-size: 3em;
  line-height: 1em;

  @media (--small-screen) {
    font-size: 2em;
    top: 15%;
  }
}

.timeline-slide__title {
  margin-bottom: 7%; 
  font-size: 1.3em;
  text-decoration: underline;
  text-decoration-color: var(--acquaColor);

  @media (--small-screen) {
    margin-top: 10%;
  }
}

.timeline-slide__subtitle {
  text-transform: uppercase;
}

.timeline-slide__content {
  padding-right: 10%;
}

.timeline-slide__list {
  list-style-type: none;
  padding: 0;
  padding-right: 10%;

  & li {
    position: relative;
    margin: 0 0 3%;
  }

  & li:before {
    content: '';
    width: 13px;
    height: 10px;
    position: absolute;
    top: 5px;
    left: -17px;
    background-image: url('../../assets/svg/arrow-li.svg');
    background-repeat: no-repeat;
  }
}

.timeline-slide__link {
  color: var(--acquaColor) !important;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  

  & span {
    text-transform: lowercase;
  }

  & p {
    position: relative;
  }

  &:hover{
    color: var(--whiteColor) !important;
  }

  &:hover:before {
    transform: translate(10%);
  }

  & p:before {
    content: '';
    width: 36.143px;
    height: 10px;
    background-image: url('../../assets/svg/arrow-link.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 5px;
    left: -40px;

    transition: transform .3s ease;
  }
}

.timeline__slide_white .timeline-slide__link:hover {
  color: var(--blueColor) !important;
}

.timeline__slide_data {
  position: absolute;
  bottom: calc(-16% + 24px);
  left: 0;

  font-size: 1.4em;
  font-weight: 600;
  letter-spacing: .03em;

  &:after{
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: -170%;
    left: 0;
    
    background-color: var(--whiteColor);
    border: solid 2px var(--blueColor);
    border-radius: 100%;
  }

  @media (--small-screen) {
    margin-top: 10%;
    top: 0;
    left: -20%;
    max-width: 10%;

    &:after{
      display: none;
    }
  }
}

.timeline__slide_blue .timeline__slide_data:after {
  background-color: var(--blueColor);
  border-color: var(--whiteColor);
}

.timeline-slide__dida {
  color: var(--acquaColor);
}