.row {
  width: 100%;
  display: inline-block;
}

figure {
  background-color: var(--acquaColor);
}

.capitolo__cover {
  width: 100%;
  height: 40vh;
  position: relative;
  overflow: hidden;
  z-index: 1;

  & img {
    width: 100%;
    position: absolute;
    top: -105%;
  }

  @media (--not-large-screen) {
    height: 60vh;

    & img {
      top: -30%;
    }
  }

  @media (--medium-screen) {
    height: 75vh;

    & img {
      top: 0;
      left: -25%;
      width: auto;
      height: 100%;
    }
  }

  @media (--small-screen) {
    height: 75vh;

    & img {
      top: 0;
      left: -25%;
      width: auto;
      height: 100%;
    }
  }
}

.capitolo__titolo {
  @apply --main-column;
  
  @media (--not-large-screen) {
    @apply --main-column-small;
  }

  @apply --mediumVerticalMargin;
}

.capitolo-titolo__text {
  font-family: Lora, Serif;
  font-size: 3.5em;
  line-height: 1.2em;
  color: var(--blueColor);

  lost-column: 4/8;
  lost-offset: 1/8;

  @media (--small-screen) {
    font-size: 2.2em;
    line-height: 1em;

    lost-column : 7/8;
    lost-offset: 1/8;
  }

  & span {
    background-color: var(--acquaColor);
  }
}

.capitolo-titolo__sottotitolo {
  font-family: Lora, Serif;
  font-size: 1.1em;
  line-height: 1.5em;
  color: var(--blackColor);
  font-style: italic;
  font-weight: 600;
  lost-column: 4/8;
  lost-offset: 1/8;
  letter-spacing: .02em;
  margin-top: 2%;

  @media (--small-screen) {
    lost-column: 6/8;
    font-size: 0.9em;
    line-height: 1.2em;
  }
}

.capitolo__articolo {
  @apply --main-column;
  position: relative;
  
  @media (--not-large-screen) {
    @apply --main-column-small;
  }
}

.articolo__titolo {
  @apply --mediumVerticalMargin;
  
  font-size: 1.5em;
  font-weight: 400;

  & span {
    color: var(--acquaColor);
    display: inline-block;
  }

  @media (--small-screen){
    font-size: 1.1em;
    font-weight: 700;
    color: var(--blueColor);
    line-height: 1em;
  }
}

.articolo__titolo_off1 {
  lost-column: 7/8;
  lost-offset: 1/8;

  & .titolo__offset {
    vertical-align: -50%;
  }
}

.articolo__titolo_off2 {
  lost-column: 6/8;
  lost-offset: 2/8;

  & .titolo__offset {
    vertical-align: -30%;
  }
}

.articolo__titolo_off3 {
  lost-column: 5/8;
  lost-offset: 3/8;

  & .titolo__offset {
    vertical-align: -40%;
  }
}

.articolo__titolo_off4 {
  lost-column: 4/8;
  lost-offset: 4/8;

  & .titolo__offset {
    vertical-align: 20%;
  }
}

.articolo__titolo_off5 {
  lost-column: 3/8;
  lost-offset: 5/8;

  & .titolo__offset {
    vertical-align: -50%;
  }
}

.articolo__titolo_off1,
.articolo__titolo_off2,
.articolo__titolo_off3,
.articolo__titolo_off4,
.articolo__titolo_off5 {
  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 2/8;
  }
}

.articolo__content {
  display: inline-block;
  width: 100%;
}

.articolo__footer,
.capitolo__footer {
  width: 100%;
  padding-bottom: 15%;
  position: relative;

  @media (--small-screen) {
    padding-bottom: 40%;
  }

  &:after {
    content: '';
    width: 28px;
    height: 100%;
    position: absolute;
    left: calc(50% - 14px);
    background-image: url('../../assets/svg/articolo-close.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.capitolo__footer {
  @apply --main-column;
  
  @media (--not-large-screen) {
    @apply --main-column-small;
  }

  &:after {
    width: 50px;
    left: calc(50% - 25px);
    background-image: url('../../assets/svg/capitolo-close.svg');
  }
}

.content__testimonianza_right,
.content__testimonianza_left {
  lost-column: 4/8 2;
  position: relative;

  @media (--small-screen) {
    lost-column: 7/8;
    lost-offset: 1/8;
  }
}

.content__testimonianza_left {
  lost-column: 4/8;

  @media (--small-screen) {
    lost-column: 7/8;
  }
}

.testimonianza__foto {
  height: 0;
  width: 85%;
  padding-bottom: 127.5%;

  & img {
    width: 100%;
  }
}

.testimonianza__cit_right,
.testimonianza__cit_left {
  position: absolute;
  top: 5%;
  left: -15%;
  width: 60%;

  @media (--medium-screen) {
    width: 80%;
  }

  @media (--small-screen) {
    display: none;
  }
}

.testimonianza__cit_left {
  left: 45%;
  text-align: right;
}

.cit__text {
  /*padding: 0 4%;*/
  display: inline;
  background-color: var(--whiteColor);
  color: var(--acquaColor);
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.5em;
  box-shadow: 5px 0 0 var(--whiteColor), -5px 0 0 var(--whiteColor);

  @media (--medium-screen) {
    font-size: 1.4em;
  }
}

.testimonianza__audio {

}

.content__corpo_sx-tre {
  lost-column: 3/8;

  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;


  }
}

.content__corpo_sx-tre-off-uno {
  lost-column: 3/8;
  lost-offset: 1/8;

  @media (--medium-screen) {
    lost-column: 5/8;
    lost-offset: 1/8;
  }

  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }
}

.content__corpo_sx-quattro {
  lost-column: 4/8;

  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }
}

.content__corpo_sx-quattro-off-uno {
  lost-column: 4/8;
  lost-offset: 1/8;
  
  @media (--medium-screen) {
    lost-column: 6/8;
    lost-offset: 2/8;
  }

  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }
}

.content__corpo_dx-tre-off-uno {
  lost-column: 3/8 2;

  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }
}

.content__corpo_dx-tre {
  lost-column: 3/8;

  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }
}

.content__corpo_dx-tre-off-cinque {
  lost-column: 3/8;
  lost-offset: 5/8;

  @media (--medium-screen) {
    lost-column: 6/8;
    lost-offset: 2/8;
  }

  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }
}

.content__corpo_dx-quattro {
  lost-column: 4/8;
  lost-offset: 4/8;

  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }
}

.content__corpo_dx-quattro-off-tre {
  lost-column:4/8;
  lost-offset:3/8;
  
  @media (--small-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }

}

.content__corpo_dx-tre-off-quattro {
  lost-column:3/8;
  lost-offset:4/8;

  @media (--small-screen){
    lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;
  }

  @media (--medium-screen) {
    lost-column: 5/8;
    lost-offset: 1/8;
  }
}

.content__caption_sx {
  lost-column: 3/8;
  lost-offset: 1/8;
  @apply --smallVerticalMargin;
  color: var(--darkGreyColor);

    @media (--small-screen){
    lost-column: 6/8;
    lost-offset: 1/8;
  }
}

.content__caption_dx {
  lost-column: 4/8;
  lost-offset: 4/8;
  @apply --smallVerticalMargin;
  color: var(--darkGreyColor);

  @media (--small-screen){
    lost-column: 6/8;
    lost-offset: 1/8;
  }
}

.content__video {
  position: relative;

  & video {
    width: 100%;
  }

  & button {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index:1;
    box-shadow: none;
    outline: none;
  }

  & button:before {
    content: '';
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    background-image: url('/assets/svg/play-video.svg');
  }

  & button:hover:before {
    background-image: url('/assets/svg/play-video-hover.svg')
  }
}

.content__video_sx,
.content__video_dx {
  margin-top: 10%;
  lost-column: 5/8;
  lost-offset: 1/8;

  @media (--small-screen) {
    lost-column: 8/8;
    lost-offset: 0/8;
  }
}

.content__video_dx {
  lost-offset: 3/8;

  @media (--medium-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;
  }
}

..content__video_sx {
  @media (--medium-screen) {
    lost-column: 6/8;
    lost-offset: 1/8;
  }
}

.corpo_underlined {
  display: inline;
  margin: 1em 0;
  background-color: var(--blueColor);
  color: var(--whiteColor);
  box-shadow: 5px 0 0 var(--blueColor), -5px 0 0 var(--blueColor);
  margin-right: 10px;
}

.corpo_cit {
  @apply --smallVerticalMargin;
  font-style: italic;
  text-indent: 2em;
}

.content__callout {
  font-size:2.5em;
  line-height:1em;
  lost-column:4/8;
  @apply --smallVerticalMargin;

  @media (--small-screen) {
    font-size: 1.8em;
    lost-column: 6/8;
  }
}

.content__callout_dx {
  lost-offset: 4/8;

  @media (--small-screen) {
    lost-offset: 1/8;
  }
}

.content__callout_center {
  lost-offset: 2/8;

  @media (--small-screen) {
    lost-offset: 1/8;
  }
}

.callout__text {
  padding-right: 15%;

  @media (--not-large-screen) {
    padding-right: 0;
  }
}

.content__grafico_A {
  lost-column: 2/8;
  margin-top: 40%;
  margin-left: 5%;

  @media (--medium-screen){
      lost-column:3/8;
      lost-offset:4/8;
      margin-top:40px;
      padding-left:60px;
  }
}

.content__grafico_B, .content__grafico_C {
  lost-column: 6/8;
  lost-offset: 1/8;
  margin-bottom: 10%;
}

.content__grafico_A, .content__grafico_B, .content__grafico_C {
  @media (--small-screen) {
    display: none;
  }

  @media (--medium-screen) {
    display: none;
  }
}

.content__grafico_mobile {
  display: none;

  @media (--small-screen) {
    display: block;
    margin: 10px 0;
  }

  @media (--medium-screen) {
    display: block;
    margin: 10px 15%;
    width: 70%;
  }
}

.content__grafico_dsm_mobile {
  display: none;

  @media (--small-screen) {
    display: block;
    margin: 10px 0;
  }
  @media (--medium-screen) {
    display: none;
  }
}



.corpo__link {
  margin-top: 15%;
  padding-left: 10%;
  color: var(--acquaColor) !important;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & span {
    text-transform: lowercase;
  }

  &:hover{
    color: var(--whiteColor) !important;
  }

  &:hover:before {
    transform: translate(10%);
  }

  &:before {
    content: '';
    width: 36.143px;
    height: 10px;
    background-image: url('../../assets/svg/arrow-link.svg');
    background-repeat: no-repeat;

    transition: transform .3s ease;
  }

  @media (--small-screen){
    padding:30px 0 0 70px;
  }
}

.corpo__link:hover {
  color: var(--blueColor) !important;
}

.scroll-menu {
  position: absolute;
  top: 0;
  height: 500px;
  padding: 0 20px;
  z-index: 99;
  background-color: rgba(255,255,255,0.8);
  border-top: solid 10px rgba(255,255,255,0);
  border-bottom: solid 10px rgba(255,255,255,0);
  border-radius: 100px;

  & ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  @media (width < 1104px) {
    display: none;
  }
}

.scroll-menu__section,
.scroll-menu__article {
}

.scroll-menu__section {
  background-color: var(--blueColor);
  width: 12px;
  height: 12px;
  margin-bottom: 12px;
  border-radius: 12px;
  cursor: pointer;
  transition: transform .2s ease;

  &:hover {
    transform: scale(1.2);
    transform-origin: 50% 50%;
    transform-origin: center center;
  };
}

.scroll-menu__article {
  position: relative;
  border-top: 1px solid var(--blueColor);
  /*background-color: var(--whiteColor);*/
  width: 8px;
  height: 8px;
  margin-left: -1px;

  transform: rotate(45deg);

  transition: all .5s ease;
  
  &:after{
    content: '';
    position: absolute;

    border-bottom: 1px solid var(--blueColor);
    width: 8px;
    height: 8px;
    bottom: 4px;
    right: 4px;
    transform: rotate(-90deg);
  }

  &.is-active {
    border: 1px solid var(--acquaColor);
    transform: rotate(0deg);
    border-radius: 8px;
    margin-left: 1px;

    &:after{
      opacity: 0;
    }
  }
} 

.scroll-menu__progress {
  position: absolute;
  top: 0;
  left: 25.5px;
  width: 1px;
  background-color: var(--blueColor);
  z-index: -1;
}

.corpo_bold {
  font-weight: 600;
}

.corpo_underlined_black {
  display: inline;
  margin: 1em 0;
  
  color: var(--whiteColor);
  box-shadow: 5px 0 0 var(--blackColor), -5px 0 0 var(--blackColor);

  background-color: var(--blackColor);
}

.home__footer {
  margin-top: 15%;
  width: 100%;
  height: auto;
  background-color: var(--blackColor);
  border-bottom: 15px solid;
  border-bottom-color: var(--acquaColor); 

}

.footer__info {
  color:var(--whiteColor);
  font-size:.9em;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  width: 60%;

  @media (--small-screen) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: calc(100% - 30px);
    padding: 30px 10px;
  }

  @media (--medium-screen) {
    margin-bottom:10%;
  }
}

.footer-credits-partA, footer-credits-partB {
  margin:0 10%;
}

.footer-credits-partA {
  & span {
    font-weight: 700;
    letter-spacing: .03em;
  }

  & p {
    margin-bottom: 15px;
  }
}

.footer-credits-partB {
  & span {
    font-weight: 700;
    letter-spacing: .03em;
    text-transform: uppercase;
    color:var(--acquaColor);
    font-size: .85em;
  }

  & p {
    margin-bottom: 15px;
  }

  & a {
    color: var(--whiteColor) !important;

    @media (--small-screen){
      text-decoration: underline;
    }
  }

  & a:hover {
    text-decoration: underline;
  }

  @media (--small-screen) {
    margin: 0 10%;
  }
}
