#landing__text {

	@media (--small-screen){
	lost-column: 6/8;
	lost-offset: 1/8;
	margin-bottom: 20px;
	}
}

#uno__pallotta_text {
	margin-top: 10%;
}

#uno__pallotta_text2 {
	margin-top: 10%;

	@media (--not-large-screen){
		margin-top: 10%;
	}
}

#uno__mappa-manicomio {
	margin-top: 5%;
  lost-column: 3/8;
  lost-offset: 1/8;
  
  background-color: #fff;

  & img {
  	width: 100%;
  }

  @media (--small-screen) {
    lost-column: 8/8;
    lost-offset: 0/8;

    @apply --mediumVerticalMargin;
  }

  @media (--medium-screen){
  	lost-column: 5/8;
  	lost-offset: 2/8;
  }
}

/*#uno-pallotta-sotto-carousel {
	margin-top: 10%;
}*/

#uno__pina-text {
  margin-top: 15%;

  @media (--small-screen) {
  	margin-top: 10%;
  	margin-bottom: 15%;
  }
}

#uno__pina-text2 {
	margin-top: 5%;
	margin-bottom: 10%;

	@media (--medium-screen){
		lost-column:5/8;
		lost-offset: 2/8;
	}
}

#uno__rossana_text {
	margin-top: 25%;
}

#uno__rossana_text2 {
	margin-top: -10%;

	@media (--not-large-screen){
		margin-top: 0;
	}

	@media (--medium-screen){
		lost-column:5/8;
		lost-offset: 1/8;
	}
}

#uno__sandro_text {
	margin-top: 15%;

	@media (--small-screen) {
		margin-bottom: 15%;
		margin-top: 5%;
	}
}

#uno__sandro_text2 {
	margin-top: 5%;
	margin-bottom: 5%;	

	@media (--medium-screen){
		lost-column:5/8;
		lost-offset: 2/8;
	}
}

#due__video {
	width: 100%;
	margin: 0 !important;
	padding-bottom: 5%;

	& video {
		width: 100%;
	}
}

#tre__lido_text {
	margin-top: 5%;

	@media (--medium-screen) {
		margin-top: 5%;
		lost-column: 4/8;
		lost-offset: 0/8;
	}


	@media (--medium-screen){
		lost-column: 5/8;
		lost-offset: 1/8; 	
	}
}


#tre__dellacqua_text {
	margin-top: -40%;
	lost-column: 3/8;

	@media (--small-screen){
		lost-column: 6/8;
    lost-offset: 1/8;
		margin-top: 10%;
	}

}

#tre-dellacqua-text2 {
	lost-column:3/8;
	lost-offset: 4/8;
	margin-top: -25%;

	@media (--medium-screen) {
		lost-column:5/8;
		lost-offset: 2/8;
		margin-top: 5%;
	}

	@media (--small-screen) {
		lost-column: 6/8;
    lost-offset: 1/8;

    text-align: justify;
    hyphens: auto;

		margin-top: 5%;
	}
}

#tre-callout-dellacqua{
	lost-offset:5/8;
	margin-top: -10%;
	lost-column: 3/8;

	@media (--small-screen) {
		lost-column: 6/8;
    lost-offset: 1/8;
    margin-top: 10%;
  }

  @media (--medium-screen){
		lost-column:4/8;
		lost-offset: 3/8;
		margin-top: 10%;
	}
}

#tre__dellacqua_foto {
	margin-top: 5%;
}


#quattro-cap-cover img {

	@media (width > 1100px) {
		top: -35%;
	}
	
	top: -5%;
	
	@media (--medium-screen) {
		top: 0%;
	}
	@media (--small-screen) {
		top: 0%;
	}
}

#quattro__giovanni_foto {
	margin-top: 15%;
}

#quattro__giovanni_text {
	lost-offset: 1/8;	
	margin-top: -30%;

	@media (--small-screen){
		margin-top: 10%;
	}
}

#quattro__giovanni_text2{
	margin-top: 5%;
} 

#quattro-giovanni-text3 {
	  lost-column: 3/8 2; 
	  lost-offset: 5/8;
	  margin-top:0%;

	  @media (--small-screen){
	  	lost-column: 6/8;
    	lost-offset: 1/8;

    	text-align: justify;
    	hyphens: auto;

	  	margin-top: 0;
	  }

	  @media (--medium-screen){
	  	lost-column: 5/8;
	  	lost-offset: 2/8;
	  }
}

#quattro-video-roma{
	margin-top: 0%;
}

#quattro__ducci_foto {
	margin-top: 10%;
}

#quattro__ducci_text {
	margin-top: -60%;
	lost-offset:5/8;

	@media (--small-screen){
		margin-top: 10%;
			lost-offset:1/8;
	}
}

#quattro__dsm {
	lost-column: 5/8;
	margin-top: 10%;

	@media (--small-screen){
		display: none;
	}

	@media (--medium-screen){
		lost-column: 7/8;
	}
}

#quattro__cipriano_text {
	margin-top: 15%;

	@media (--small-screen){
		margin: 10% 0;
	}
}

#quattro__cipriano_citazione {
	left:-90px;
	margin-top:-15px;
	@media (--medium-screen){
		margin-top: -30px;
	}
}

#quattro__cipriano_text2 {
	@media (--medium-screen){
		lost-column:5/8;
		lost-offset:2/8;
		margin-top: 10%;
	}
}

#quattro-cipriano-callout{
	margin-top: -5%;
	margin-bottom:10%;

	@media (--small-screen) {
    margin-top: 10%;
  }

  @media (--medium-screen) {
    margin-top: 10%;
    lost-column: 5/8;
    lost-offset: 3/8;
  }
}

#quattro-grafico-fonte{
	font-family: 'Lora',serif;
	color: var(--blackColor);
	font-style: italic;
	font-size: .8em;
	margin-top: -5%;
	margin-bottom: 10%;
	lost-offset:1/8;

	& a{
		text-decoration: underline;
	}

	@media (--small-screen){
		display:none;
	}
	@media (--medium-screen){
		display:none;
	}
}

#quattro-grafico-text{
	lost-column:4/8;

	@media (--small-screen){
		lost-column:6/8;
		lost-offset:1/8;
	}
}

#quattro-corpo-link {
	text-align: left;

	@media (--small-screen){
		lost-column:6/8;
		margin-top:5%;
		margin-bottom:10%;
	}
}

#quattro__grazia_text {
	margin-top: -55%;
	lost-offset:4/8;

	@media (--small-screen){
		margin-top: 10%;
		lost-offset:1/8;
	}
}

#quattro-grazia-callout{
	margin-top: 10%;
	margin-bottom: 10%;

}

#cinque-alice-text {
	@media (--medium-screen) {
		lost-column:5/8;
		lost-offset:2/8;
	}
}

#cinque__alice_text1 {
	lost-offset:3/8;

	@media (--small-screen) {
		lost-offset:1/8;
	}
	@media (--medium-screen) {
		lost-column:5/8;
		lost-offset:2/8;
	}
}

#cinque__alice_text2 {
	margin-top: 10%;
	lost-column: 6/8;
  lost-offset: 2/8;

  text-align: left;
  hyphens: none;
}

#cinque__title_last {
	margin-top: 10%;
}

#cinque__title_mobile {
	margin: 20px 20% 0;
	width: 60%;

	@media (--medium-screen) {
		width: 90%;
		margin:5%;
		padding:0;
	}

}

