.home__navigation {
  width: 100%;
  position: fixed;
  top: 0;

  
  z-index: 99999;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  z-index: 2;

  @media (--small-screen) {
    display: none;
  }

}

.home__navigation_mobile {
  display: none;
  min-height: 100vh;
  overflow-y: scroll;
  background-color: var(--blackColor);

  & .navigation__main {
    
    top: calc(100vh - 150px);
    padding-top: 18px;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
  
    & li a{
      color: var(--acquaColor);
      font-size: 1.3em;
    }
  
    & li {
      text-align: center;
      padding-bottom: 10px;
      margin:0;
    }
  }

  & .navigation__sub {
    visibility: visible;
    width:100%;
    height: auto;
    background-color: var(--blueColor);
    font-size: 0.8em;
    font-weight: 600;
    border-top: 10vh solid white;
    padding: 8vh 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & li {
      color: var(--whiteColor);
      text-align: center;
      padding: 10px;
      border-bottom: 1px solid;
      margin: 0 15%;
    }

    &:before {
      content:"Matti per sempre";
      color: white;
      font-size: 2.5em;
      text-align: center;
      padding-bottom: 20px;

      @media (--mini-screen) {
        font-size:2em;
      }
    }

    @media (--mini-screen){
      padding: 6vh 0;
    }
  }


}

.navigation__main,
.navigation__sub {
  font-size: 0.75em;
  font-weight: 600;
  width: calc(100% - 40px);
  padding: 0 20px;
  margin: 0;

  display: flex;
  justify-content: space-between;
  
  list-style-type: none;

  & li {
    padding: 0 10px;
    line-height: 3.2em;
    cursor: pointer;

    @media (--small-screen) {
      line-height: 2em;
    }

    @media (--medium-screen){
        line-height: 1.5em;
        padding: 7px 3px;
        text-align: center;
    }
  }
}

.navigation__main {
  justify-content: flex-end;
  background-color: var(--blackColor);
  /*border-bottom: solid 2px var(--blueColor);*/
  
    & li {
      margin-left: 12px;
      text-align: right;
  
      @media (--medium-screen){
        padding: 15px 5px;
      }

      @media (--small-screen){
        padding: 10px 5px;
        margin-left: 0;
      }

      @media (--mini-screen) {
        padding-bottom: 5px;
        padding-top: 0px;
      }

    & a {
      color: var(--whiteColor);
    }
  }

  & .is-selected {
    background-color: var(--acquaColor);
    color: var(--whiteColor);
  }

  & li:hover {
    background-color: var(--acquaColor);

    & a {
      color: var(--whiteColor);
    }
  }
}

.navigation__sub {
  background-color: var(--whiteColor);
  visibility: hidden;
  height: 0;

  & li {
    color: var(--blueColor); 

  }

   & li:hover {
    color: var(--whiteColor)!important;
    background-color: var(--blueColor);
  }

  & .is-active {
    color: var(--whiteColor);
    background-color: var(--blueColor);
  }
}


.footer__navigation {
  font-size: 0.8em;
  font-weight: 600;
  padding: 20px 0;
  margin:0;
  lost-column:3/8;
  lost-offset:5/8;
  display: flex;
  list-style-type: none;
  text-transform: uppercase;

  & a {
    margin: 0;
    padding-right: 40%;
    line-height: 2em;
    cursor: pointer;
    color: var(--whiteColor)!important;
    outline: none;

    @media (--medium-screen){
      padding-right: 0;
      padding-left: 15%;
    }

  }
   & a:hover {
    color: var(--acquaColor)!important;
   }

   @media (--small-screen){
    display:none;
   }

   @media (--medium-screen){
      lost-offset:4/8;
      padding-left:10%;
    }

}

/* ---------- hamburger ----------*/


.hamburger-menu {
  position: fixed;
  top: 20px;
  right: 20px;
  margin: auto;
  width: 40px;
  height: 30px;
  cursor: pointer;
  display: none;
  z-index: 3;

  @media (--small-screen) {
    display: block;
  }
}

.bar,
.bar:after,
.bar:before {
  width: 40px;
  height: 5px;
}

.bar {
  position: relative;
  transform: translateY(12.5px);
  background: var(--blueColor);
  transition: all 0ms 300ms;
}
.bar.animate {
  background: rgba(255, 255, 255, 0);
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 12.5px;
  background: var(--blueColor);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar:after {
  content: "";
  position: absolute;
  left: 0;
  top: 12.5px;
  background: var(--blueColor);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
